<template>
  <base-view
    title="Traspasos"
    description="Realiza traspasos entre unidades administrativas"
    background="bg-gradient-purple-to-black"
    icon="repeat"
    header-type="overlap"
  >
    <!-- El value permite que se muestre o no el modal para registrar nuevo traspaso.  -->
    <traspasos-modal v-model="showModal" :mes="selectedMonth"></traspasos-modal>

    <b-overlay :show="!Boolean(periodoActivo)">
      <template #overlay>
        <loading></loading>
      </template>

      <b-card no-body>
        <b-card-header>
          <div class="row align-items-center">
            <div class="col">
              <check-authorization :requiresAuthorizations="['acceso periodos fiscales']">
                <router-link :to="{ name: 'PeriodosFiscales' }">
                  <b-button size="sm">
                    Cambiar periodo fiscal activo
                  </b-button>
                </router-link>
              </check-authorization>
            </div>
            <div class="col">
              <x-periodo-fiscal-tag classBody="col-4 offset-8 right-content" variant="primary" />
            </div>
          </div>
        </b-card-header>

        <div class="row my-3 p-3">
          <div class="col-md-4 mb-4" v-for="month in getMonths" :key="month.id">
            <a class="card lift h-100 my-cursor" @click="selectMonth(month.id)">
              <div class="card-body d-flex justify-content-center flex-column">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="mr-3">
                    <i class="feather-xl text-primary mb-3" data-feather="calendar"></i>
                    <h5>{{ month.value }}</h5>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </base-view>
</template>

<script>
import TraspasosModal from '@/components/Traspasos/TraspasosModal'

export default {
  name: 'TraspasosView',

  components: {
    TraspasosModal
  },

  created () {
    this.init()
  },

  data () {
    return {
      showModal: false,

      selectedMonth: 0,

      periodoActivo: null
    }
  },

  computed: {
    getMonths () {
      return this.$store.getters['UIModule/getMonths'].slice(0, 12)
    }
  },

  methods: {
    /**
     * Inicializa el componente para seleccionar el periodo fiscal actualmente activo
     */
    async init () {
      this.periodoActivo = await this.$getPeriodoFiscalActivo()

      this.$store.dispatch('traspasoModule/listar', {
        periodo_fiscal_id: this.periodoActivo.id,
        autorizar: false
      })
    },

    selectMonth (value) {
      this.selectedMonth = value

      this.showModal = true
    }
  }
}
</script>
